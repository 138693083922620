import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["host"];
import React from "react";
import { CataloguePageContainer, CataloguePageInitialProps, CataloguePageType } from "@catchoftheday/catalogue";
import { jsx as ___EmotionJSX } from "@emotion/react";

var CategoryPage = function CategoryPage(_ref) {
  var host = _ref.host,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(CataloguePageContainer, _extends({
    host: host,
    pageType: CataloguePageType.CATEGORY
  }, props));
};

CategoryPage.getInitialProps = CataloguePageInitialProps;
export default CategoryPage;